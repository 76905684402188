<script lang="ts" setup>
import Asterisk from '~/components/form-elements/Asterisk.vue'

const slots = useSlots()

const props = withDefaults(
  defineProps<{
    isRequired?: boolean
    value?: string | null
  }>(),
  { isRequired: false, value: null }
)
</script>

<template>
  <div v-if="value || slots.default" class="label-text">
    <template v-if="value">
      {{ value }}
    </template>
    <slot v-else />

    <Asterisk v-if="isRequired" />
  </div>
</template>

<style lang="scss" scoped>
@use 'assets/css/variables';

.label-text {
  color: variables.$main-gray-color;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}
</style>
